import React, { useState, useEffect } from "react";
import NotificationsList from "src/component/notifications";
import { Grid, Box, Typography, Link } from "@material-ui/core";
import Page from "src/component/Page";
import ApiConfig from "src/config/APIConfig";
import axios from "axios";


export default function Notifications({ popUp }) {
  const [iserror, setIserror] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [announcements, setAnnouncement] = useState([]);
  const [notificationDetails, setDetails] = useState([]);
  const accessToken = window.localStorage.getItem("creatturAccessToken");

  const getAnnouncementData = () => {
    axios
      .get(
        ApiConfig.getAnnouncement,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
        {
          announcementsId: 1234,
        }
      )
      .then((response) => {
        if (response.data.status !== 200) {
          setIserror(true);
          setIsSuccess(false);
          setAlertMsg(response.data.message);
        } else {
          console.log("response", response);
          setAnnouncement(response.data.data);
          //   history.push('/login')
        }
        setIsUpdating(false);
      })
      .catch((response) => {
        setIsUpdating(false);
        // setOpen(true);
        console.log("response", response);
      });
  };

  const getNotificationtData = () => {
    axios
      .get(
        ApiConfig.getNotification,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        },
        {
          role: "USER",
          userId: 1234,
        }
      )
      .then((response) => {
        if (response.data.status !== 200) {
          setIserror(true);
          setIsSuccess(false);
          setAlertMsg(response.data.message);
        } else {
          console.log("response", response);
          setDetails(response.data.data)
          //   history.push('/login')
        }
        setIsUpdating(false);
      })
      .catch((response) => {
        setIsUpdating(false);
        // setOpen(true);
        console.log("response", response);
      });
  };

  useEffect(() => {
    getNotificationtData();
    getAnnouncementData();
  }, []);

  return (
    <Page title="Notifications">
      <Box mb={3}>
        <Box display="flex">
          <Typography variant="h6" className="extra-bold" pt={2}>
            Notifications
          </Typography>
          {popUp && (
            <Box pt={1} style={{ flex: "1 0" }} textAlign="right">
              <Typography variant="body2">
                <Link
                  href="/notifications"
                  // component={RouterLink}
                >
                  View all
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Grid container spacing={4} sm={12} style={{ margin: "0" }}>
        {popUp
          ? announcements.map((data, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  key={i}
                  style={{ padding: "15px 0" }}
                >
                  <NotificationsList popUp={popUp} data={data} index={i} />
                </Grid>
              );
            })
          : announcements.map((data, i) => {
              return (
                <Grid item xs={12} sm={12} md={12} key={i}>

                  <NotificationsList popUp={popUp} data={data} index={i} notDetails={notificationDetails}/>
                </Grid>
              );
            })}
      </Grid>
    </Page>
  );
}
