import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
const Logo = (props) => {
  const history = useHistory();
  //   return (
  //     <img
  //       onClick={() => history.push("/dashboard")}
  //       src="/images/logo.png"
  //       alt="Logo"
  //       sizes="small"
  //       style={{ cursor: "pointer", width: "100px" }}
  //       {...props}
  //     />
  //   );
  return (
    <span
      onClick={() => history.push("/dashboard")}
      style={{
        fontSize: "30px",
        marginLeft: "60px",
        marginTop: "20px",
        color: "#00e0b0",
      }}
      {...props}
    >
      XYZ
    </span>
  );
};
export default Logo;
