import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import ApiConfig from "src/config/APIConfig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
  } else {
    localStorage.removeItem("creatturAccessToken");
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserDate] = useState({});

  const userDetails = async (accessToken) => {
    if (accessToken) {
      try {
        const response = await axios.get(ApiConfig.myaccount, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.data.status !== 200) {
          if (response.data.status === 205) {
            console.log("ERROR", response.data);
          }
        } else {
          console.log(response.data.data);
          setUserDate(response.data.data);
        }
      } catch (err) {
        console.error(err.response);
      }
    }
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("creatturAccessToken");
    if (accessToken) {
      userDetails(accessToken);
    }
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
      userDetails(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
