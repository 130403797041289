import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import HomeLayout from 'src/layouts/HomeLayout';
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/auth/Main")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/Fauth",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/2FA/index")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/transaction-history",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TransactionHistory/")),
  },
  // {
  //   exact: true,
  //   path: '/trade',
  // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import('src/views/pages/Trade/')),
  // },
  {
    exact: true,
    path: "/bank-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BankDetails")),
  },
  {
    exact: true,
    path: "/my-wallet",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyWallet/index")),
  },
  {
    exact: true,
    path: "/notifications",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/notifications/index")),
  },
  {
    exact: true,
    path: "/notification-detail",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/notifications/NotificationDetails")
    ),
  },
  {
    exact: true,
    path: "/faq",
    // guard:true,
    component: lazy(() => import("src/views/pages/faq/index")),
  },

  {
    exact: true,
    path: "/add-kyc",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYC")),
  },
  {
    exact: true,
    path: "/kyc",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/KYC/KycList")),
  },

  // {
  //   exact: true,
  //   path: "/buy-token1",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/BuyToken/BuyToken")),
  // },
  // {
  //   exact: true,
  //   path: "/card-payment",
  //   // guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/CardPayment/CardPayment")),
  // },
  {
    exact: true,
    path: "/buy-token",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BuyTokenPayment")),
  },

  {
    exact: true,
    path: "/terms-and-condition",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/terms-and-condition-main",
    // guard: true,
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/user-info",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/user-info/index")),
  },
  {
    exact: true,
    path: "/forget-password",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/change-password",
    // guard:true,
    component: lazy(() => import("src/views/auth/change-password")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/instrauctions",
    // guard:true,
    component: lazy(() => import("src/views/auth/forget-password-link/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    // guard:true,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/buy",
    component: lazy(() => import("src/views/pages/Trade/Buy/")),
  },
  {
    exact: true,
    path: "/sell",
    component: lazy(() => import("src/views/pages/Trade/Sell/")),
  },
  {
    exact: true,
    path: "/contact-us",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/ContactUS/")),
  },
  {
    exact: true,
    path: "/currency",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Currency/")),
  },
  {
    exact: true,
    path: "/ticket",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Ticket/")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
