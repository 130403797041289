import { Typography, Box, IconButton, Link } from "@material-ui/core";
import React, { useState } from "react";
import { FaRegTrashAlt, FaChevronRight } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";

export default function NotificationsList({ data, popUp ,notDetails}) {
  const [open, setOpen] = useState(false);

  const confirmationHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      className="notication-list"
      style={
        popUp
          ? {
              borderBottom: "1px solid #ccc",
              padding: "0px 5px 7px",
              position: "relative",
              color: "#000",
            }
          : {
              borderBottom: "1px solid #ccc",
              padding: "0px 10px 15px",
              position: "relative",
              color: "#000",
            }
      }
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"title"}
          desc={"desc"}
          confirmationHandler={confirmationHandler}
        />
      )}
      <Box display="flex">
        <Box>
          <IconButton
            onClick={() => setOpen(true)}
            style={{ backgroundColor: "#ccc", marginRight: "12px" }}
          >
            <FaRegTrashAlt size={14} />
          </IconButton>
        </Box>
        <Link
          to={{
            pathname: "/notification-detail",
            state: {
              data: data,
            },
          }}
          style={{ textDecoration: "none", width: "100%" }}
          component={RouterLink}
        >
          <Box style={{ width: "calc(100% - 50px)" }}>
            <Box className="rightPosition d-flex" style={{ marginTop: "10px" }}>
              <Typography variant="subtitle2" pt={2}>
                {data.date}
                <FaChevronRight size={14} style={{ marginLeft: "15px" }} />
              </Typography>
            </Box>
            <Box className="width120">
              <Typography variant="subtitle2" className="extra-bold">
                {data.title}
              </Typography>
              <Typography variant="subtitle2" pt={2} className="ellispsys">
                {data.description}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
