// const url = "https://ico-java.mobiloitte.com";
const url = "https://java-stablecoin.mobiloitte.org";
// const url = "http://172.16.0.230:4107";     http://172.16.0.217:4104/
// const url = "http://172.16.0.217:4104";

const account = `${url}/account`;
const auth = `${url}/auth`;
const notification = `${url}/notification`;
const staticData = `${url}/static`;
const wallet = `${url}/wallet`;

const ApiConfig = {
  auth: `${auth}`,
  googleTwoFAAuth: `${auth}/verify-google`,
  getCountryListOne: `${account}/get-country-list`,
  signUp: `${account}/signup`,
  verifyUser: `${account}/verify-user`,
  myaccount: `${account}/my-account`,
  addUserBankAccount: `${account}/add-user-bank-account`,
  getUserAccountList: `${account}/get-user-account-list`,
  uploadFile: `${account}/upload-file`,
  saveKycDetails: `${account}/save-kyc-details`,
  deleteUserBankAccount: `${account}/delete-user-bank-account`,
  getAllKycDetails: `${account}/get-all-kyc-details`,
  getForgetPassword: `${account}/forget-password/mobiloitApp`,
  resetPassword: `${account}/reset-password-mobile-app`,
  changePassword: `${account}/change-password`,
  sendSmsCode: `${account}/send-sms-code`,
  verifySmsCode: `${account}/verify-sms-code`,
  googleAuthentication: `${account}/google-auth`,
  verifyGcode: `${account}/verify-google-code`,
  twoFaDisable: `${account}/twoFa-disable`,

  //Static
  getFaqList: `${staticData}/get-faq-list`,
  submitContactUsRequest: `${staticData}/submit-contact-us-request`,
  getStaticPageData: `${staticData}/get-static-page-data`,

  //wallet
  getAllUserBalanceAndCoinListWithName: `${wallet}/wallet/get-all-user-balance-and-coinlist-withName`,
  getAllUserBalanceAndCoinlist: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  getDeposits: `${wallet}/wallet/get-deposits`,
  getAddress: `${wallet}/wallet/get-address`,
  getAsyncTransfer: `${wallet}/wallet-type2/get-async-transfer`,
  gettype2Deposit: `${wallet}/wallet-type2/get-deposits`,
  withdraw: `${wallet}/wallet/withdraw`,
  approve: `${wallet}/wallet/approve-withdraw`,
  getAllTransactionHistory: `${wallet}/wallet/get-all-transaction-history`,
  cancel: `${wallet}/wallet/cancel-withdraw`,
  getCoinList: `${wallet}/coin/get-coin-list`,
  ticket: `${wallet}/user/get-usdEuro-request-api`,
  euro: `${wallet}/wallet/get-balance-in-usd-or-euro`,
  walletAVT: `${wallet}/wallet/avt-withdraw-internal`,
  placeOrder: `${wallet}/basic-exchange/place-buy-order`,
  getCoinDetails: `${wallet}/coin/get-coin-details`,
  getCoinMarketPrice: `${wallet}/basic-exchange/get-market price`,

  //notification
  getNotification: `${notification}/get-notification-data`,
  getAnnouncement: `${notification}/get-announcement-data`,

  readNotification: `${notification}/read-notification`,

  paybycard: `${url}/wallet/basic-exchange/buy-token`,
};

export default ApiConfig;
