import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Box, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import ApiConfig from "src/config/APIConfig";
import Alert from "@material-ui/lab/Alert";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function ModifyTwoFA({
  open,
  handleClose,
  submitHandler,
  fromLogin,
  token,
}) {
  const [otp, setOtp] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [iserror, setIserror] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [gAuthDialog, setGdialog] = useState("");
  const [secretKey, setSecKey] = useState("");
  const [tokenForGauth, settokenForGauth] = useState("");
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  const [authValue, setAuthValue] = React.useState("SMS");
  const auth = useContext(AuthContext);
  const history = useHistory();

  const [isVerify, setIsVerify] = useState(false);

  useEffect(() => {
    setAuthValue(auth.userData.twoFaType);
  }, [auth.userData.twoFaType]);

  const handleAuthMethod = (event) => {
    setAuthValue(event.target.value);
  };

  const sendSMSHandler = async () => {
    try {
      const response = await axios.get(ApiConfig.sendSmsCode, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("verifyGAuthHandler", response);
      if (response.data.status !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.message);
      } else {
        setGdialog(true);
        setIsSuccess(true);
        setIserror(false);
        setAlertMsg(response.data.message);
      }
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log("ERROR", err);
    }
  };

  const confirmationHandler = async () => {
    if (auth.userData.twoFaType === "GOOGLE" && authValue === "SMS") {
      setGdialog(true);
      //   //   disableAuthHandler();
      //   //   await verifyGAuthHandler();
      //   await sendSMSHandler();
      //   await verifySMSHandler();
    }
    if (auth.userData.twoFaType === "SMS" && authValue === "GOOGLE") {
      //   await verifySMSHandler();
    }
    if (auth.userData.twoFaType === "GOOGLE" && authValue === "NONE") {
      disableAuthHandler();
    }
  };

  const verifyGAuthHandler = async () => {
    setIsUpdating(true);

    try {
      const response = await axios.post(
        ApiConfig.googleTwoFAAuth,
        {
          code: otp,
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.data.status !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.message);
      } else {
        setIsSuccess(true);
        setIserror(false);
        setAlertMsg(response.data.message);
        console.log(response);
      }
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log("ERROR", err);
    }
  };

  const verifySMSHandler = async () => {
    setIsUpdating(true);

    try {
      const response = await axios.post(
        ApiConfig.verifySmsCode,
        {
          code: otp,
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.data.status !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.message);
      } else {
        setIsSuccess(true);
        setIserror(false);
        setAlertMsg(response.data.message);
        setTimeout(() => {
          setGdialog(false);
          setOtp("");
        }, 1000);
        console.log(response);
      }
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log("ERROR", err);
    }
  };

  const disableAuthHandler = async () => {
    setIsUpdating(true);

    try {
      const response = await axios.post(
        ApiConfig.twoFaDisable,
        {
          code: otp,
        },
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(response);
      if (response.data.status !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.message);
      } else {
        setIsVerify(true);
        setIsSuccess(true);
        setIserror(false);
        setAlertMsg(response.data.message);
        setOtp("");
        sendSMSHandler();

        console.log(response);
      }
      setIsUpdating(false);
    } catch (err) {
      setIsUpdating(false);
      console.log("ERROR", err);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Modify Authentication Method`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Authentication Method"
                  name="AuthMethod"
                  value={authValue}
                  onChange={handleAuthMethod}
                >
                  {/* <FormControlLabel
                    value="SMS"
                    control={<Radio />}
                    label="SMS"
                  /> */}
                  <FormControlLabel
                    value="GOOGLE"
                    control={<Radio />}
                    label="Google"
                  />
                  <FormControlLabel
                    value="NONE"
                    control={<Radio />}
                    label="None"
                  />
                </RadioGroup>
              </FormControl>{" "}
            </Box>
          </DialogContentText>
          {iserror && (
            <Box my={2}>
              <Alert severity="error">{alertMsg}</Alert>
            </Box>
          )}
          {isSuccess && (
            <Box mb={2}>
              <Alert severity="success">{alertMsg}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => confirmationHandler()}
            disabled={isUpdating}
          >
            Submit{isUpdating && <ButtonCircularProgress />}
          </Button>
          <Button
            disabled={isUpdating}
            variant="containedPrimary"
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={gAuthDialog}
        // onClose={handleGauth}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Confirm Authentication `}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <FormControl component="fieldset">
                <Typography variant="body1" style={{ marginBottom: "10px" }}>
                  Enter code sent in Google authenticator app
                </Typography>
                <TextField
                  style={{ width: "33%" }}
                  type="number"
                  onChange={(e) => setOtp(e.target.value)}
                />
                {/* <TextField  placeholder='enter code sent in Gapp'/> */}
              </FormControl>{" "}
            </Box>
          </DialogContentText>
          {iserror && (
            <Box my={2}>
              <Alert severity="error">{alertMsg}</Alert>
            </Box>
          )}
          {isSuccess && (
            <Box mb={2}>
              <Alert severity="success">{alertMsg}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              (auth.userData.twoFaType === "SMS" &&
                authValue === "GOOGLE" &&
                !isVerify) ||
              (auth.userData.twoFaType === "GOOGLE" &&
                authValue === "SMS" &&
                !isVerify)
                ? disableAuthHandler()
                : verifySMSHandler();
            }}
            disabled={isUpdating}
          >
            Confirm{isUpdating && <ButtonCircularProgress />}
          </Button>
          <Button
            disabled={isUpdating}
            variant="containedPrimary"
            onClick={handleClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
