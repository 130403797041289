import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",
    background: "#ffffff",

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 360,
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={5} className={classes.left}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <img
            src="/images/login.png"
            style={{ width: "80%", maxWidth: 480, margin: "20px 0" }}
            alt=""
            onClick={() => history.push("/")}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={7}>
        <Box className={classes.content}>{children}</Box>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
