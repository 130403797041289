import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ButtonCircularProgress from './ButtonCircularProgress';
import Alert from '@material-ui/lab/Alert';

export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
  isUpdating,
  iserror,
  isSuccess,
  alertMsg,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={isUpdating}
            onClick={() => {
              confirmationHandler();
            }}
            autoFocus
          >
            Yes {isUpdating && <ButtonCircularProgress />}
          </Button>
          <Button
            variant="containedPrimary"
            onClick={handleClose}
            disabled={isUpdating}
          >
            No
          </Button>
        </DialogActions>
        <Box mt={2}>
          {iserror && (
            <Box my={2}>
              <Alert severity="error">{alertMsg}</Alert>
            </Box>
          )}
          {isSuccess && (
            <Box mb={2}>
              <Alert severity="success">{alertMsg}</Alert>
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
