import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
//import typography from "./typography";

const baseOptions = {
  typography: {
    fontFamily: `"Poppins", "Helvetica", "Arial", sans-serif`,
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
    },
  },
  overrides: {
    MuiGrid: {
      MuiBox: {
        root: {
          padding: "15px",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "block",
      },
    },

    // ReacttelInput: {
    //   formControl: {
    //     width: "100%",
    //     borderRadius: "24px",
    //   },
    //   flagDropdown: {
    //     borderRadius: "24px,0,0,24px",
    //   },
    // },

    MuiTab: {
      root: {
        borderRadius: 0,
        border: "none",
        borderBottom: "none",
        "&$selected": {
          backgroundColor: "#edf5f3",
          border: "none",
        },
      },
    },

    muiTab: {
      wrapper: {
        alignContnet: "flex-start",
      },
    },

    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },

    MuiPaper: {
      transition: "transform 1.5s ease-in-out;",
      rounded: {
        borderRadius: 10,
        padding: 10,
        "&:hover": {
          backgroundColor: "#f5f5f5",
          boxShadow: "0 0 20px rgb(98 208 157 / 19%)",
        },
      },
      outlined: {
        borderRadius: 10,
        padding: 10,
      },
      elevation2: {
        boxShadow: "0 0 20px rgb(98 208 157 / 19%)",
      },
    },

    // MuiInputBase: {
    //   root: {
    //     // margin: "10px 0px",
    //   },
    // },

    MuiStepContent: {
      root: {
        marginLeft: 281,
        marginTop: 0,
        paddingLeft: 250,
        paddingRight: 300,
      },
    },

    MuiStepLabel: {
      iconContainer: {
        order: 2,
        paddingLeft: 30,
      },
      labelContainer: {
        width: 226,
        textAlign: "right",
      },
    },

    MuiStepConnector: {
      vertical: {
        marginLeft: 281,
        padding: 0,
      },
      lineVertical: {
        borderLeftWidth: "1px",
      },
      "&$active": {
        borderColor: "#00e0b0",
      },
    },

    MuiStepIcon: {
      backgroundColor: "white",

      completed: {
        backgroundColor: "#edf5f3",
        border: "none",
      },
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: "25px",
        "&:hover": {},
      },
      adornedEnd: {
        //  backgroundColor: "rgb(232, 240, 254)",
        backgroundColor: "transparent",
      },
    },

    MuiTableHead: {
      root: {
        backgroundColor: "#00e0b0",
        color: "white",
      },
    },
    MuiInputBase: {
      root: {
        "& > input[type=number]": {
          "-moz-appearance": "textfield",
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
    },
    MuiTableCell: {
      head: {
        color: "white",
      },
    },
    MuiDialogTitle: {
      root: {
        minWidth: "300px",
      },
    },
    MuiSelect: {
      "& .select": {
        "&:focus": {
          background: "transparent",
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: "none",
        borderRadius: 27,
        "&:hover": {
          boxShadow: "none",
        },
      },
      contained: {
        border: "1px solid #00e0b0",
        backgroundColor: "#00e0b0",
        color: "white",
        "&:hover": {
          backgroundColor: "#00e0b0",
        },
      },
      containedPrimary: {
        border: "1px solid #00e0b0",
        backgroundColor: "#f2f2f2",
        color: "#2a2a2a",
        borderColor: "#f2f2f2",
        "&:hover": {
          backgroundColor: "#f2f2f2",
          color: "#2a2a2a",
        },
      },
      containedTerm: {
        border: "1px solid #00e0b0",
        backgroundColor: "#f2f2f2",
        color: "#2a2a2a",
        borderColor: "#f2f2f2",
        "&:hover": {
          backgroundColor: "#f2f2f2",
          color: "#2a2a2a",
        },
      },
      outlined: {
        backgroundColor: "white",
        color: "#00e0b0",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      outlinedPrimary: {
        backgroundColor: "white",
        color: "#52565c",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      outlinedSecondary: {
        backgroundColor: "white",
        color: "#00e0b0",
        "&:hover": {
          backgroundColor: "white",
        },
      },
      containedSizeL: {
        backgroundColor: "#ffffff",
        color: "#000000",
        padding: "14px 50px",
      },
      outlinedSizeSmall: {
        padding: "4px 15px",
      },
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        background: "transparent",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#00e0b0",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#00e0b0",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
